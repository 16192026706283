import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import API from "../../../../api/api"

import Preloader from '../../../particles/Preloader'
import TrashCan from "../../../particles/TrashCan"
import Pencil from "../../../particles/Pencil"

import images from '../../../../assets/images/imgs'
import { createNotification } from "../../../../redux/actions/notificationsActions"

function ProjectsView() {
  const [projects, setProjects] = useState([])
  const [preloader, setPreloader] = useState(true)
  const navigate = useNavigate()

  useEffect(() => { loadProjects() }, [])

  function loadProjects() {
    setPreloader(true)

    API.getProjects()
      .then(res => {
        setProjects(res.data.projects)
        console.log(res.data.projects)
      })
      .catch(err => console.log(err))
      .finally(() => {
        setPreloader(false)
      })
  }

  function remove(id) {
    if (window.confirm('Are you sure?')) {
      API.deleteProject(id)
        .then((res) => {
          loadProjects()
          createNotification(res.data.message, 'success')
        })
    }
  }

  function update(id) {
    navigate('/project/update/' + id)
  }

  function getDomain(link) {
    if (!link) return ''

    const regex = /https?:\/\/([^/:]+)/
    const match = link.match(regex)

    // Extracting the domain name
    const domainName = match ? `${match[1]}...` : link

    return domainName
  }

  return (
    <>
      {
        preloader ? (
          <div className="preloaders">
            <Preloader height="50px" />
            <Preloader height="50px" />
            <Preloader height="50px" />
          </div>
        ) : (
          <div className="column">
            <Link className="btn btn-success" to='/project/create'>Create project</Link>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <b>#</b>
                  </th>
                  <th scope="col">
                    <b>Title</b>
                  </th>
                  <th scope="col">
                    <b>Link</b>
                  </th>
                  <th scope="col">
                    <b>Content</b>
                  </th>
                  <th scope="col">
                    <b>Image</b>
                  </th>
                  <th scope="col">
                    <b>Developers</b>
                  </th>
                  <th scope="col">
                    <b>Date Create</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  projects.map((project, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">
                          <TrashCan callback={() => remove(project._id)} />
                          <Pencil callback={() => update(project._id)} />
                        </th>
                        <td>{project.title}</td>
                        <td>{getDomain(project.link)}</td>
                        <td>{project.content}</td>
                        <td>
                          {
                            <img
                              width={100}
                              src={project.image || images.preview}
                              alt="preview" />
                          }
                        </td>
                        <td>
                          {
                            project.developers.map((item, i) => {
                              return <p key={i} style={{
                                border: '1px solid #ccc',
                                padding: '2px',
                                borderRadius: '3px',
                                marginBottom: '5px'
                              }}>{item}</p>
                            })
                          }
                        </td>
                        <td>{project.dateCreate}</td>
                      </tr>
                    )
                  })
                }
                {
                  projects.length === 0 ? (
                    <tr>
                      <td colSpan={5}>No items</td>
                    </tr>
                  ) : ''
                }
              </tbody>
            </table>
          </div>
        )
      }
    </>
  )
}

export default ProjectsView
