import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import FileBase64 from 'react-file-base64'
import ReactQuill from 'react-quill'

import API from "../../../../api/api"
import Preloader from "../../../particles/Preloader"
import TrashCan from "../../../particles/TrashCan"
import { createNotification } from '../../../../redux/actions/notificationsActions'
import { errorFormater } from '../../../../utils/errors'

function ProjectUpdate() {
  let { id } = useParams()
  const navigate = useNavigate()
  const [preloader, setPreloader] = useState(true)
  const [project, setProject] = useState({
    id,
    title: '',
    link: '',
    image: '',
    content: '',
    isDeleteImage: false,
    isModifiedImage: false
  })

  function setContent(content) {
    setProject({ ...project, content })
  }

  useEffect(() => {
    API.getProject(id)
      .then(res => setProject(project => ({ ...project, ...res.data.project })))
      .catch(err => console.log(err))
      .finally(() => {
        setPreloader(false)
      })
  }, [id])

  function update(e) {
    e.preventDefault()

    API.updateProject(project)
      .then(res => {
        const { message } = res.data
        createNotification(message, 'success')
        navigate('/projects')
      })
      .catch(err => {
        const { data } = err.response
        const error = errorFormater(data)

        createNotification(error, 'error')
      })
  }

  function createDeveloper(event) {
    const text = event.target.value

    // Pressed "Enter" button & text should not ebe empty
    if (event.which === 13 && text.trim()) {
      event.preventDefault()

      setProject({ ...project, developers: [...project.developers, text] })

      event.target.value = ''
    }

    // Pressed "Delete" button & text should be empty
    if (event.which === 8 && !text.trim()) {
      event.preventDefault()

      setProject({ ...project, developers: project.developers.slice(0, -1) })
      event.target.value = ''
    }

    // Pressed "Enter" button & text should be empty
    if (event.which === 13 && !text.trim()) {
      event.preventDefault()
      return false
    }
  }

  function removeDeveloper(position) {
    const { developers } = project

    setProject({
      ...project,
      developers: developers.filter(text => text !== position)
    })
  }

  return (
    <>
      {
        preloader ? (
          <div className="preloaders">
            <Preloader height="50px" />
            <Preloader height="50px" />
            <Preloader height="50px" />
          </div>
        ) : (
          <form onSubmit={update} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className='form-group'>
              <label htmlFor='title'>Project title</label>
              <input
                value={project.title}
                onChange={event => setProject({ ...project, title: event.target.value })}
                type='text'
                className='form-control'
                id='title'
                placeholder='Enter title' />
            </div>

            <div className='form-group'>
              <label htmlFor='link'>Project url</label>
              <input
                value={project.link}
                onChange={event => setProject({ ...project, link: event.target.value })}
                type='text'
                className='form-control'
                id='link'
                placeholder='Enter link' />
            </div>

            <div className='form-group special'>
              <label htmlFor='file'>Project content</label>
              <ReactQuill
                theme="snow"
                value={project.content}
                onChange={setContent} />
            </div>

            <div className='form-group'>
              {
                project.image ? (
                  <>
                    <img
                      width={150}
                      src={project.image}
                      alt="preview" />

                    <TrashCan
                      styles={{ marginLeft: '10px' }}
                      callback={() => setProject({
                        ...project,
                        image: '',
                        isDeleteImage: true,
                        isModifiedImage: true
                      })} />
                  </>
                ) : (
                  <>
                    <label htmlFor='file'>Select preloader image for project</label>

                    <FileBase64
                      id='file'
                      className='form-control-file'
                      multiple={false}
                      onDone={({ base64 }) => setProject({
                        ...project,
                        image: base64,
                        isDeleteImage: false,
                        isModifiedImage: true
                      })}
                      type='file' />
                  </>
                )
              }
            </div>

            <div className='form-group'>
              <label htmlFor='file'>Project developers</label>

              <ul className='content-list'>
                {
                  project.developers.map((developer, i) =>
                    <li key={i} className="content-list-item">
                      {developer}
                      <span onClick={() => removeDeveloper(developer)} className='remove-content-list-item'>&times;</span>
                    </li>)
                }
                <li>
                  <input
                    placeholder='Press enter to add'
                    onKeyDown={e => createDeveloper(e)}
                    type="text" />
                </li>
              </ul>
            </div>

            <button type='submit' className='btn btn-primary special'>Update project</button>
          </form>
        )
      }
    </>
  )
}

export default ProjectUpdate